<template>
  <div class="cointer">
    <div class="sititle">
      <div class="siti">{{ form.name }}</div>
      <div class="siback">
        <!-- <router-link :to="{ path: 'technicalIndex' }" class="siback"> -->
        <div class="sibimg">
          <img
            alt=""
            src="https://api.gbservice.cn/88356f0bc29e9e2e3ca34fcbf8d6c98f.png"
          />
        </div>
        <div class="sicback" @click="goBack()">返回</div>
        <!-- </router-link> -->
      </div>
    </div>
    <div class="stepcont">
      <el-steps :active="active" :space="200" finish-status="success">
        <el-step title="形式审核"></el-step>
        <el-step title="技术审核"></el-step>
        <el-step title="公示公告"></el-step>
        <el-step title="标识发放"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
    </div>
    <div class="sicontent">
      <div class="sicleft">
        <router-link
          :to="{
            path: '/formal/formDetail?project_id=' + project_id + '&from=2',
          }"
          class="detailPush"
        >
          <div class="siclkuai">
            <div class="siclleft">申报信息</div>
            <div class="siczhtai">
              <el-tag v-if="shenbao_check.pass == 1" type="success"
                >达标</el-tag
              >
              <el-tag v-else-if="shenbao_check.pass == 2" type="danger"
                >未达标</el-tag
              >
              <el-tag v-else-if="shenbao_check.pass == 3" type="warning"
                >待定</el-tag
              >
              <el-tag v-else type="info">未审查</el-tag>
            </div>
          </div>
        </router-link>
        <router-link
          :to="{
            path: '/formal/totalTable?project_id=' + project_id + '&from=2',
          }"
          class="detailPush"
        >
          <div class="siclkuai">
            <div class="siclleft">能效测评汇总表</div>
            <div class="siczhtai">
              <el-tag v-if="nengxiao_check.pass == 1" type="success"
                >达标</el-tag
              >
              <el-tag v-else-if="nengxiao_check.pass == 2" type="danger"
                >未达标</el-tag
              >
              <el-tag v-else-if="nengxiao_check.pass == 3" type="info"
                >待定</el-tag
              >
              <el-tag v-else type="info">未审查</el-tag>
            </div>
          </div>
        </router-link>
        <div @click="gotoTable" class="detailPush">
          <div class="siclkuai">
            <div class="siclleft">
              <div class="siclname">能效测评报告</div>
              <div class="dowlend" @click.stop="downloadFile(3)">下载</div>
            </div>
            <div class="siczhtai">
              <el-tag v-if="option1.pass == 1" type="success">达标</el-tag>
              <el-tag v-else-if="option1.pass == 2" type="danger"
                >未达标</el-tag
              >
              <el-tag v-else-if="option1.pass == 3" type="warning">待定</el-tag>
              <el-tag v-else type="info">未审查</el-tag>
            </div>
          </div>
        </div>
        <router-link
          :to="{
            path: '/formal/ipendTable?project_id=' + project_id + '&from=2',
          }"
          class="detailPush"
        >
          <div class="siclkuai">
            <div class="siclleft">能效标识申报指标情况表</div>
            <div class="siczhtai">
              <el-tag v-if="zhibiao_check.pass == 1" type="success"
                >达标</el-tag
              >
              <el-tag v-else-if="zhibiao_check.pass == 2" type="danger"
                >未达标</el-tag
              >
              <el-tag v-else-if="zhibiao_check.pass == 0" type="info"
                >待定</el-tag
              >
              <el-tag v-else type="info">未审查</el-tag>
            </div>
          </div>
        </router-link>
        <div>
          <div class="siclkuai">
            <div class="siclleft">
              <div class="siclname">附件</div>
              <div class="dowlend" @click="downloadFile(4)">下载</div>
            </div>
            <div class="siczhtai">
              <el-tag v-if="jishu_fujian_check.pass == 1" type="success"
                >达标</el-tag
              >
              <el-tag v-else-if="jishu_fujian_check.pass == 2" type="danger"
                >未达标</el-tag
              >
              <el-tag v-else-if="jishu_fujian_check.pass == 3" type="info"
                >待定</el-tag
              >
              <el-tag v-else type="info" style="cursor: pointer">未审查</el-tag>
              <!--  @click="dialogVisible = true" -->
            </div>
          </div>
        </div>
      </div>
      <div class="siccenter">
        <div class="sicctitle">形式审查意见汇总</div>
        <div class="sicctxt">
          <p v-if="shenbao_check.idea">申报信息: {{ shenbao_check.idea }}</p>
          <p v-if="nengxiao_check.idea">
            能效测评汇总表: {{ nengxiao_check.idea }}
          </p>
          <p v-if="baogao_check.idea">能效测评报告: {{ baogao_check.idea }}</p>
          <p v-if="zhibiao_check.idea">
            能效标识申报指标情况表: {{ zhibiao_check.idea }}
          </p>
        </div>
        <div class="sicctitle">技术审查意见汇总</div>
        <div class="sicctxt">
          <table width="100%" border="1" cellspacing="0">
            <tbody>
              <tr>
                <th scope="col">&nbsp;项目名称</th>
                <th scope="col" colspan="3">{{ form.name }}</th>
              </tr>
              <tr>
                <th scope="row">审核指标&nbsp;</th>
                <th colspan="2">&nbsp;申报情况</th>
                <th>&nbsp;评审意见</th>
              </tr>
              <tr>
                <th scope="row">相对节能率&nbsp;</th>
                <td colspan="2">{{ formtable.evaluationWay_heatEnergy }}</td>
                <td>{{ result.export_heatEnergy }}</td>
              </tr>
              <tr>
                <th scope="row">节能率&nbsp;</th>
                <td colspan="2">{{ formtable.relateEnergy }}</td>
                <td>{{ result.export_relateEnergy }}</td>
              </tr>
              <tr>
                <th scope="row">&nbsp;基础项</th>
                <td colspan="2">
                  经测评，本项目基础项、规定项均满足《民用建筑能效测评标识标准》DB32/T
                  3964-2020标准要求，建筑节能率为{{
                    formtable.buildEnergyRatio
                  }}，测评合格。
                </td>
                <td>{{ option1.idea }}</td>
              </tr>
              <tr>
                <th scope="row">规定项&nbsp;</th>
                <td colspan="2">
                  经测评，该项目规定项{{
                    formtable.strip
                  }}条参评，均满足《民用建筑能效测评标识标准》DB32/T
                  3964-2020规定要求；
                </td>
                <td>{{ option2.idea }}</td>
              </tr>
              <tr>
                <th scope="row">&nbsp;选择项</th>
                <td colspan="2">
                  经测评，该项目选择项{{ formtable.strip }}条参评，加{{
                    formtable.grades
                  }}分；
                </td>
                <td>{{ option3.idea }}</td>
              </tr>
              <tr>
                <th scope="row">建筑热工性能&nbsp;</th>
                <td colspan="2">
                  屋面保温:{{ formtable.keepwarm }};面传热系数[W/(m2·K)]:{{
                    formtable.heattransfer
                  }};外墙保温:{{ formtable.roofWarm }};外墙传热系数[W/(m2·K)]:{{
                    formtable.outHeat
                  }};外窗型材:{{
                    formtable.exterProfile
                  }}
                  外窗传热系数[W/(m2·K)]：东{{ formtable.outEaast }}、南{{
                    formtable.outSouth
                  }}、西{{ formtable.outWest }}、北{{
                    formtable.outNorth
                  }};遮阳系数SC：东{{ formtable.scEaast }}、南{{
                    formtable.scSouth
                  }}、 西{{ formtable.scWest }}、北{{ formtable.scNorth }}。
                </td>
                <td>{{ result.export_buildhot }}</td>
              </tr>
              <tr>
                <th scope="row">单位建筑面积全年能耗量</th>
                <td colspan="2">{{ formtable.buildEnergy }}</td>
                <td>{{ result.export_buildEnergy }}</td>
              </tr>
              <tr>
                <th scope="row">&nbsp;专家评审意见 *</th>
                <td colspan="3">{{ result.export_totalopinion }}</td>
              </tr>
              <tr>
                <th scope="row" rowspan="3">&nbsp;结 论 *</th>
                <td colspan="2">&nbsp;相对节能率(%)：</td>
                <td>{{ result.export_evaluationWay }}</td>
              </tr>
              <tr>
                <td colspan="2">&nbsp;单位建筑面积能量（kW·h/m2）：</td>
                <td>{{ result.export_areacoldenergy }}</td>
              </tr>
              <tr>
                <td colspan="2">&nbsp;星级（无、一星、二星、三星）：</td>
                <td>
                  <i
                    v-for="item in result.after_stars"
                    :key="item"
                    class="el-icon-star-on"
                  ></i>
                </td>
              </tr>
              <tr>
                <th scope="row">&nbsp;评审人员</th>
                <td>{{ form.zname }}</td>
                <th>&nbsp; 评审时间</th>
                <td>
                  &nbsp;{{ zhuanjiaCheckTime | formatDate("yyyy-MM-dd") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="yjdow" @click="downloadTable">下载意见表</div>
      </div>
      <div class="sicright">
        <div class="sirigh">
          <div class="sicctitle">历史记录</div>
          <div class="sicrtxt">
            <el-timeline :reverse="reverse">
              <el-timeline-item
                v-for="(activity, index) in log"
                :key="index"
                :timestamp="activity.created_at"
              >
                {{ activity.type }}
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
    </div>
    <!-- v-if="showBtn" -->
    <div class="scibtn" v-if="showBtn">
      <el-button type="primary" @click="backXingShen"
      >打回形式复审</el-button
      >
      <el-button type="primary" @click="back_expert">返回专家</el-button>
      <el-button type="primary" :disabled="form.status < 6" @click="back"
        >打回企业</el-button
      >
       <!-- <el-button type="primary" @click="pass">待公示</el-button> -->
    </div>
    <!-- 弹窗 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <el-radio-group v-model="fujian_pass">
        <el-radio :label="1">达标</el-radio>
        <el-radio :label="2">未达标</el-radio>
        <el-radio :label="3">待定</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="checkFujian">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import {
  checkSave,
  projectTableInfo,
  skillBack,
  changeStatus,
} from "../../../../api/project";

import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";

export default {
  filters: {
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      dialogVisible: false,
      reverse: true,
      idea: "",
      activities: [
        {
          content: "提交",
          timestamp: "2018-04-15",
        },
        {
          content: "打回修改",
          timestamp: "2018-04-13",
        },
        {
          content: "提交",
          timestamp: "2018-04-11",
        },
      ],
      project_id: "",
      form: {},
      formtable: {},
      result: {},
      checks: [],

      //形式审查的状态 1-6
      shenbao_check: {},
      nengxiao_check: {},
      baogao_check: {},
      zhibiao_check: {},
      fujian_check: {},

      //技术审查  7-11
      option1: {},
      option2: {},
      option3: {},
      jishu_fujian_check: {},
      log: [],

      fujian_pass: "",

      word_data: {
        evaluationWay_heatEnergy: "无",
        export_heatEnergy: "无评审意见",
        relateEnergy: "无",
        export_relateEnergy: "无评审意见",
        buildEnergyRatio: "无",
        idea1: "无评审意见",
        idea2: "无评审意见",
        idea3: "无评审意见",
        strip: 0,
        grades: 0,
        keepwarm: "无",
        heattransfer: "无",
        roofWarm: "无",
        outHeat: "无",
        exterProfile: "",
        outEaast: "无",
        outSouth: "无",
        outWest: "无",
        outNorth: "无",
        scEaast: "无",
        scSouth: "无",
        scWest: "无",
        scNorth: "无",
        export_buildhot: "无评审意见",
        buildEnergy: "无",
        export_buildEnergy: "无评审意见",
        export_totalopinion: "无评审意见",
        export_evaluationWay: "无评审意见",
        export_areacoldenergy: "无评审意见",
        after_stars: "无评审意见",
        zname: "无",
        now: "无",
      },
      showBtn: true,
      active: 1,
      //from 2是技术审查，3是公示公告
      from: 2,
      zhuanjiaCheckTime: '', //专家评审时间
    };
  },
  mounted() {
    this.project_id = this.$route.query.id;
    // if (this.$route.query.active){
    //   this.active = parseInt(this.$route.query.active);
    // }
    // if (this.$route.query.from){
    //   this.from = this.$route.query.from
    // }
    this.getInfo();
  },
  methods: {
    goBack() {
      if (this.active == 3) {
        this.$router.push({
          path: "/mark/identMake",
        });
      } else if (this.active == 4) {
        this.$router.push({
          path: "/mark/identSend",
        });
      } else {
        this.$router.push({
          path: "/technology/technicalIndex",
        });
      }
      // this.$router.go(-1);
      // if (this.from==3){
      //   this.$router.push({
      //     path: '/announcement/publicIndex'
      //
      //   });
      // }else {
      //   this.$router.push({
      //     path: '/technology/technicalIndex'
      //   });
      // }

      // this.$router.back()
    },
    gotoTable() {
      this.$router.push({
        path: "teexportTable?project_id=" + this.project_id,
      });
    },

    //下载文件
    downloadFile(type) {
      let files = this.form.files;
      let download_files = [];
      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          if (files[i].type == type) {
            download_files.push(files[i]);
          }
        }
        if (download_files && download_files.length > 0) {
          for (let i = 0; i < download_files.length; i++) { 
            const iframe = document.createElement("iframe");
            iframe.style.display = "none"; // 防止影响页面
            iframe.style.height = 0; // 防止影响页面
            iframe.src = download_files[i].url;
            document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
            // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
            setTimeout(() => {
              iframe.remove();
            }, 5 * 60 * 1000);
          }
        } else {
          this.$message.warning("没有文件可下载！");
        }
      } else {
        this.$message.warning("没有文件可下载！");
      }
    },

    //去除对象中值为null的属性
    removePropertyOfNull(obj) {
      Object.keys(obj).forEach((item) => {
        if (!obj[item]) delete obj[item];
      });
      return obj;
    },

    // 下载评审表
    downloadTable() {
      Object.assign(this.word_data, this.removePropertyOfNull(this.form));
      Object.assign(this.word_data, this.removePropertyOfNull(this.formtable));
      Object.assign(this.word_data, this.removePropertyOfNull(this.result));
      if (this.option1.idea) {
        this.word_data.idea1 = this.option1.idea;
      }
      if (this.option2.idea) {
        this.word_data.idea2 = this.option2.idea;
      }
      if (this.option3.idea) {
        this.word_data.idea3 = this.option3.idea;
      }
      let now = new Date();
      this.word_data.now =
        now.getFullYear() +
        "-" +
        (now.getMonth() + 1) +
        "-" +
        now.getDate() +
        " " +
        now.getHours() +
        ":" +
        now.getMinutes() +
        ":" +
        now.getSeconds();
      console.log(this.word_data);

      let _this = this;
      // 判断有无附加商品来选择word模版
      // 读取并获得模板文件的二进制内容
      JSZipUtils.getBinaryContent("mingyongmu.docx", function (error, content) {
        console.log("-----", content);
        // input.docx是模板。我们在导出的时候，会根据此模板来导出对应的数据
        // 抛出异常
        if (error) {
          throw error;
        }
        // 创建一个JSZip实例，内容为模板的内容
        let zip = new JSZip(content);
        console.log("+++++", zip);
        // 创建并加载docxtemplater实例对象
        let doc = new Docxtemplater();
        console.log("/////", doc);
        doc.loadZip(zip);
        console.log("=====", doc);
        // 设置模板变量的值
        doc.setData({
          // 导出价格表全部信息
          ..._this.word_data,
        });
        try {
          // 用模板变量的值替换所有模板变量
          doc.render();
        } catch (error) {
          // 抛出异常
          let e = {
            message: error.message,
            name: error.name,
            stack: error.stack,
            properties: error.properties,
          };
          console.log(JSON.stringify({ error: e }));
          throw error;
        }
        // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
        let out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        // 将目标文件对象保存为目标类型的文件，并命名
        saveAs(out, _this.word_data.name + "的民用建筑能效标识评审表.docx");
      });
    },

    //通过
    pass() {
      let params = {
        ids: [this.project_id],
        status: 9,
        token: this.$store.state.token,
      };
      if (this.shenbao_check.pass != 1) {
        this.$message.warning("需要前面所有步骤达标！");
        return false;
      }
      if (this.nengxiao_check.pass != 1) {
        this.$message.warning("需要前面所有步骤达标！");
        return false;
      }
      if (this.baogao_check.pass != 1) {
        this.$message.warning("需要前面所有步骤达标！");
        return false;
      }
      if (this.zhibiao_check.pass != 1) {
        this.$message.warning("需要前面所有步骤达标！");
        return false;
      }
      if (this.fujian_check.pass != 1) {
        this.$message.warning("需要前面所有步骤达标！");
        return false;
      }
      if (this.option1.pass != 1) {
        this.$message.warning("需要前面所有步骤达标！");
        return false;
      }
      if (this.jishu_fujian_check.pass != 1) {
        this.$message.warning("需要前面所有步骤达标！");
        return false;
      }

      if (this.form.status != 8) {
        this.$message.warning("需要先通过专家审核！");
        return false;
      }

      let that = this;
      changeStatus(params).then((res) => {
        console.log(res);
        that.$message.success("成功通过");
        that.$router.push({
          path: "./technicalIndex",
        });
      });
    },

    //返回给专家
    back_expert() {
      this.$confirm("此操作项目将返回专家, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: this.project_id,
            token: this.$store.state.token,
            back: 1,
          };
          let that = this;
          skillBack(params).then((res) => {
            console.log(res);
            that.$message.success("打回成功");
            that.$router.push({
              path: "./technicalIndex",
            });
          });
          this.$message({
            message: "打回成功!",
          });
        })
        .catch(() => {
          this.$message({
            message: "已取消打回",
          });
        });
    },

    //打回形式复审
    backXingShen(){
      let params = {
        token: this.$store.state.token,
        status: 3,
        ids: [this.project_id],
      };
      let that = this;
      changeStatus(params).then((res) => {
        console.log(res);
        that.$message.success("打回形式复审成功");
        that.$router.push({
          path: "./technicalIndex",
        });
      });
    },

    //打回
    back() {
      this.$confirm("此操作项目将打回企业, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: this.project_id,
            token: this.$store.state.token,
          };
          let that = this;
          skillBack(params).then((res) => {
            console.log(res);
            that.$message.success("打回成功");
            that.$router.push({
              path: "./technicalIndex",
            });
          });
          this.$message({
            message: "打回成功!",
          });
        })
        .catch(() => {
          this.$message({
            message: "已取消打回",
          });
        });
    },
    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.form = res.data;
        console.log(that.form.status);
        if (
          that.form.status > 8 &&
          that.form.status != 15 &&
          that.form.status != 16 &&
          that.form.status != 17 &&
          that.form.status != 18 &&
          that.form.status != 19
        ) {
          that.showBtn = false;
          this.from = 3;
          if (that.form.status < 12) {
            this.active = 2;
          }
          if (
            that.form.status == 12 ||
            that.form.status == 13 ||
            that.form.status == 23
          ) {
            this.active = 4;
          } else {
            this.active = 3;
          }
        }
        that.log = res.data.log;
        that.formtable = that.form.detail;
        if (!that.formtable) {
          that.formtable = {};
        }
        that.result = that.form.report;
        if (!that.result) {
          that.result = {};
        }
        that.checks = res.data.checks;
        for (let i = 0; i < that.checks.length; i++) {
          if (that.checks[i].type == 1) {
            that.shenbao_check = that.checks[i];
          } else if (that.checks[i].type == 2) {
            that.nengxiao_check = that.checks[i];
          } else if (that.checks[i].type == 3) {
            that.baogao_check = that.checks[i];
          } else if (that.checks[i].type == 4) {
            that.zhibiao_check = that.checks[i];
          } else if (that.checks[i].type == 5) {
            that.fujian_check = that.checks[i];
          } else if (that.checks[i].type == 6) {
            that.zhibiao_check = that.checks[i];
          } else if (that.checks[i].type == 7) {
            that.option1 = that.checks[i];
          } else if (that.checks[i].type == 8) {
            that.option2 = that.checks[i];
          } else if (that.checks[i].type == 9) {
            that.option3 = that.checks[i];
          } else if (that.checks[i].type == 10) {
            that.jishu_fujian_check = that.checks[i];
          }else if (that.checks[i].type == 11) {
            //专家是否通过的 check
            that.zhuanjiaCheckTime = that.checks[i].created_at
          }
        }
      });
    },

    //附件审查
    checkFujian() {
      let params = {
        idea: "",
        pass: this.fujian_pass,
        project_id: this.project_id,
        type: 10,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        that.$message.success("保存成功");
        that.getInfo();
        that.dialogVisible = false;
      });
    },
  },
};
</script>
<style scoped>
/deep/.el-textarea > textarea {
  height: 176px;
}
/deep/ .el-textarea__inner {
  border: 0;
}
</style>
<style lang="scss" scoped>
.detailPush {
  text-decoration: none;
}

.sititle {
  margin: 47px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .siti {
    margin-left: 310px;
    font-size: 30px;
    font-family: Adobe Heiti Std;
    color: #333333;
  }

  .siback {
    display: flex;
    flex-direction: row;

    img {
      width: 28px;
      height: 28px;
    }

    .sicback {
      height: 28px;
      line-height: 28px;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      color: #3086fb;
      margin-left: 6px;
      cursor: pointer;
    }
  }
}

.stepcont {
  width: 1150px;
  margin: 50px auto;
}

.sicontent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 15px;

  .sicleft {
    width: 33%;
        margin-top: 3%;
    .siclkuai {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .siclleft {
        width: 200%;
        height: 80px;
        line-height: 80px;
        background: #ffffff;
        border-radius: 4px;
        border-left: 10px solid #54acff;
        margin-bottom: 17px;
        padding-left: 32px;
        font-size: 24px;
        color: #54acff;
        font-family: Adobe Heiti Std;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        text-decoration: none;

        .siclname {
          font-size: 24px;
          text-decoration: none;
        }

        .dowlend {
          text-align: right;
          margin-right: 15px;
          color: #3086fb;
          font-size: 14px;
          cursor: pointer;
        }
      }

      .siczhtai {
        width: 30%;
        margin: 30px 32px;
      }
    }
  }

  .siccenter {
    width: 33%;
    .sicctitle {
      font-size: 24px;
      font-family: Adobe Heiti Std;
      margin-top: 10px;
      margin-bottom: 5px;
    }

    .sicctxt {
      width: 100%;
      height: 20vh;
      background: #ffffff;
      box-shadow: 0px 3px 8px 1px rgba(204, 204, 204, 0.3);
      border-top: 10px solid #4aa1fe;
      border-radius: 4px;
      overflow-y: scroll;
    }
    .sicctxt::-webkit-scrollbar {
      display: none;
    }
  }

  .sicright {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .sicctitle {
      font-size: 24px;
      font-family: Adobe Heiti Std;
      margin-bottom: 22px;
    }

    .sicrtxt {
      width: 340px;
      height: 42vh;
      overflow-y: scroll;
      background: #ffffff;
      box-shadow: 0px 3px 8px 1px rgba(204, 204, 204, 0.3);
      border-radius: 4;
      padding: 20px 0;
    }
    .sicrtxt::-webkit-scrollbar {
      display: none;
    }
  }
}

.yjdow {
  display: flex;
  flex-direction: row-reverse;
  color: #3086fb;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
}

.scibtn {
  margin-top: 44px;
  text-align: center;
}
</style>
